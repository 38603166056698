import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import React from "react";
import ReactDOM from "react-dom";
import SignatureCanvas from "react-signature-canvas";

import './Tab1.css';



const w=''+window.innerWidth
const h=''+window.innerHeight
const self=this
const cor=true
let sigPad:any = {}
const clear=function() {
  sigPad.clear()
}
let penClr="black"


//console.log('PEN COLOR', color)
const toggle = document.querySelector('#themeToggle');
//let pc=document.documentElement.style.getPropertyValue("--pen-color");
/*if (["rgba(0, 0, 0, 0)","rgba(18, 18, 18, 18)","rgb(18, 18, 18)","rgb(0, 0, 0, 0)"].includes(window.getComputedStyle( document.body ,null).getPropertyValue('background-color'))) {
  penClr="white"
}
setTimeout(function() {
    console.log('canvascol', sigPad.getCanvas())
},200)
//console.log('PC', pc)
console.log('theme', toggle)*/
const Tab1: React.FC = () => {
  
  return (
    
    <IonPage>
      <IonContent fullscreen>
        <SignatureCanvas
     dotSize={2} clearOnResize={true} penColor={penClr}
    canvasProps={{ width:w, height: h ,className: "sigCanvas",}}
    ref={(ref) => { sigPad = ref }}
  />,
      
      </IonContent>  
    </IonPage>
  );
};

export default Tab1;
